import { FileWithPath } from 'react-dropzone';
import { UploadedFile } from '../components/Dropzone';

export function readFile(file: FileWithPath): Promise<UploadedFile> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        resolve({
          filePath: file.path || file.webkitRelativePath,
          content: reader.result as string,
        });
      }
    };
    reader.onerror = reject;

    reader.readAsText(file);
  });
}
