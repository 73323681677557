import React from 'react';
import { styled } from '../../stitches.config';
import { violet, mauve } from '@radix-ui/colors';
import { CheckIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import Flex from '../../shared/components/Flex';
import { InlineBox } from '.';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  padding: '0 15px',
  fontSize: 13,
  lineHeight: 1,
  flexGrow: 0,
  height: 32,
  gap: 5,
  backgroundColor: '$selectBg',
  border: '1px solid $borderMuted',
  cursor: 'pointer',
  color: '$fgDefault',
  svg: {
    fill: '$fgDefault',
  },
});

const StyledIcon = styled(SelectPrimitive.SelectIcon, {
  color: '$fgDefault',
});

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: '$selectBg',
  border: '1px solid $borderMuted',
  borderRadius: 6,
  boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: '$3',
});

function Content({ children, ...props }: { children: JSX.Element }) {
  return (
    <SelectPrimitive.Portal>
      <StyledContent {...props}>{children}</StyledContent>
    </SelectPrimitive.Portal>
  );
}

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  fontSize: 13,
  lineHeight: 1,
  color: '$fgDefault',
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-highlighted]': {
    background: '$colorAccent',
    color: '$fgDefault',
  },
});

const StyledLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: mauve.mauve11,
});

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5,
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: violet.violet11,
  cursor: 'default',
};

const StyledScrollUpButton = styled(SelectPrimitive.ScrollUpButton, scrollButtonStyles);

const StyledScrollDownButton = styled(SelectPrimitive.ScrollDownButton, scrollButtonStyles);

// Exports
export const RadixSelect = SelectPrimitive.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = StyledIcon;
export const SelectContent = Content;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;

// Your app...
const Box = styled('div', {});

type SelectItemValue = {
  value: string;
  label: string;
  id: string | number;
};

interface SelectProps {
  items: SelectItemValue[];
  onChange?: (value: string) => void;
  defaultValue?: string;
  label?: string;
  value?: string;
}

export const Select = ({ items, onChange, defaultValue, label = 'Item', value }: SelectProps) => {
  return (
    items && (
      <Box>
        <RadixSelect defaultValue={defaultValue} onValueChange={onChange}>
          <SelectTrigger aria-label={label}>
            <SelectValue>
              <Flex css={{ gap: '$2' }}>
                <InlineBox css={{ color: '$fgSubtle' }}>{label}</InlineBox>
                <InlineBox>{value || defaultValue}</InlineBox>
              </Flex>
            </SelectValue>
            <SelectIcon>
              <TriangleDownIcon />
            </SelectIcon>
          </SelectTrigger>
          <SelectContent>
            <>
              <SelectViewport>
                {items.map((item) => {
                  return (
                    <SelectItem value={item.value} key={item.id}>
                      <SelectItemText>{item.label}</SelectItemText>
                      <SelectItemIndicator>
                        <CheckIcon />
                      </SelectItemIndicator>
                    </SelectItem>
                  );
                })}
              </SelectViewport>
            </>
          </SelectContent>
        </RadixSelect>
      </Box>
    )
  );
};

export default Select;
