import React, { useEffect, useState } from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { styled } from '../stitches.config';
import { keyframes } from '@stitches/react';
import { Box, Button, Flex, Input, Label, Spinner } from '../shared/components';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { usePlaygroundContext } from '../context/PlaygroundContext';
import publishTokenSet from '../api/publishTokenSet';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { UploadedFile } from './Dropzone';
import useDefaultSetId from '../hooks/useDefaultSetId';
import { Mixpanel } from '../Mixpanel';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const AlertOverlay = styled(AlertDialogPrimitive.Overlay, {
  opacity: 0.5,
  backgroundColor: '$canvasSubtle',
  position: 'fixed',
  inset: 0,
});

const AlertContainer = styled(AlertDialogPrimitive.Content, {
  backgroundColor: '$canvasDefault',
  border: '1px solid $borderMuted',
  borderRadius: 6,
  position: 'fixed',
  top: '50%',
  left: '50%',
  padding: '$8 $9',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '500px',
  maxHeight: '85vh',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': { outline: 'none' },
});

function AlertDialogContent({ children, ...props }: { children: React.ReactNode }) {
  return (
    <AlertDialogPrimitive.Portal>
      <AlertOverlay />
      <AlertContainer {...props}>{children}</AlertContainer>
    </AlertDialogPrimitive.Portal>
  );
}

interface PublishModalProps {
  onClose: () => void;
  visible: boolean;
}

export default function PublishModal({ onClose, visible }: PublishModalProps) {
  const { iframeRef } = usePlaygroundContext();
  const setId = useDefaultSetId();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [publishing, setPublishing] = useState(false);
  const [userEmail, setEmail] = useState('');
  const [author, setAuthor] = useState('');
  const [twitterHandle, setHandle] = useState('');
  const [setName, setTokensetName] = useState('My custom set');

  const onSubmit = () => {
    if (userEmail) {
      setPublishing(true);
      iframeRef.current?.contentWindow?.postMessage({ type: 'sd-input-files-request' }, '*');
    } else {
      addNotification(NotificationTypes.ERORR, 'Email is required');
    }
  };

  const resetState = () => {
    setEmail('');
    setTokensetName('');
    setHandle('');
    setAuthor('');
  };

  async function onMessage(ev: MessageEvent) {
    if (ev.data.type === 'sd-input-files') {
      const files = ev.data.files as UploadedFile[];
      if (files) {
        const jsonFiles = files.filter((file) => file.filePath.includes('.json'));

        try {
          await publishTokenSet({
            userEmail,
            setName,
            twitterHandle,
            author,
            setId: uuid(),
            approved: false,
            files: jsonFiles,
          });
        } catch (error) {
          addNotification(NotificationTypes.ERORR, 'Something went wrong. Please try again');
        }
      }
      addNotification(
        NotificationTypes.SUCCESS,
        'Set was published. Once a moderator approves it will be visible in the zen garden'
      );
      Mixpanel.track('Publish set', {
        userEmail,
        setName,
        twitterHandle,
        author,
      });
      onClose();
      resetState();
      setPublishing(false);
      navigate(`/explore/${setId}`);
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  });

  return (
    <AlertDialogPrimitive.Root open={visible}>
      <AlertDialogContent>
        <Flex css={{ flexDirection: 'column', alignItems: 'center', gap: '$5' }}>
          <Flex css={{ fontWeight: '$semiBold', fontSize: '$xxxxlarge', color: '$fgDefault' }}>
            We need some details!
          </Flex>
          <Flex css={{ width: '100%', flexDirection: 'column', alignItems: 'flex-start', gap: '$1' }}>
            <Box>
              <Label htmlFor="email-input">E-mail</Label>
            </Box>
            <Input
              placeholder="Enter E-mail"
              type="email"
              id="email-input"
              required
              value={userEmail}
              onChange={(ev) => {
                setEmail(ev.target.value);
              }}
            />
          </Flex>
          <Flex css={{ width: '100%', flexDirection: 'column', alignItems: 'flex-start', gap: '$1' }}>
            <Box>
              <Label htmlFor="author-input">Author</Label>
            </Box>
            <Input
              placeholder="Your name"
              type="text"
              id="author-input"
              required
              value={author}
              onChange={(ev) => {
                setAuthor(ev.target.value);
              }}
            />
          </Flex>
          <Flex css={{ width: '100%', flexDirection: 'column', alignItems: 'flex-start', gap: '$1' }}>
            <Box>
              <Label htmlFor="twitter-input">Twitter handle</Label>
            </Box>
            <Input
              placeholder="@twitterhandle"
              type="text"
              id="twitter-input"
              value={twitterHandle}
              onChange={(ev) => setHandle(ev.target.value)}
            />
          </Flex>
          <Flex css={{ width: '100%', flexDirection: 'column', alignItems: 'flex-start', gap: '$1' }}>
            <Box>
              <Label htmlFor="set-name-input">Token set name</Label>
            </Box>
            <Input
              placeholder="Enter a name for your token set"
              type="text"
              id="set-name-input"
              value={setName}
              onChange={(ev) => setTokensetName(ev.target.value)}
            />
          </Flex>
          <Flex css={{ justifyContent: 'start', width: '100%', gap: '$5' }}>
            <AlertDialogPrimitive.Cancel asChild>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </AlertDialogPrimitive.Cancel>
            <AlertDialogPrimitive.Action asChild>
              <Button variant="primary" css={{ width: '75px' }} onClick={onSubmit}>
                {publishing ? <Spinner size="1" thickness="1" color="white" /> : 'Publish'}
              </Button>
            </AlertDialogPrimitive.Action>
          </Flex>
        </Flex>
      </AlertDialogContent>
    </AlertDialogPrimitive.Root>
  );
}
