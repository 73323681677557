import React, { createContext, ReactNode, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

export interface Notification {
  message: string;
  type: NotificationType;
  id: string;
}

export enum NotificationTypes {
  ERORR,
  SUCCESS,
}

export type NotificationType = NotificationTypes.ERORR | NotificationTypes.SUCCESS;

export interface NotificationContextType {
  notifications: Notification[];
  addNotification: (type: NotificationType, message: string) => void;
  removeNotification: (id: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

function NotificationContextProvider({ children }: { children: ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (type: NotificationType, message: string) => {
    const notification = {
      id: uuid(),
      message,
      type,
    };

    const newNotifications = [...notifications, notification];
    setNotifications(newNotifications);
  };

  const removeNotification = (id: string) => {
    const newNotifications = notifications.filter((notification) => notification.id !== id);
    setNotifications(newNotifications);
  };

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
}

function useNotifications() {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationContextProvider');
  }
  return context;
}

export { NotificationContextProvider, useNotifications };
