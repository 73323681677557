import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlaygroundContext } from '../context/PlaygroundContext';
import { useTokenSets } from '../hooks/useTokenSets';
import { Mixpanel } from '../Mixpanel';
import { Select } from '../shared/components/Select';

export interface Theme {
  id: string;
  name: string;
  selectedTokenSets: Record<string, string>;
}

export default function ThemeDropdown() {
  const { slug } = useParams();
  const { tokenSets } = useTokenSets();
  const [selectedTheme, setSelectedTheme] = useState('');
  const { iframeRef, uploadedThemes } = usePlaygroundContext();

  const tokenSet = useMemo(() => {
    return tokenSets.find((item) => item.attributes.setId === slug);
  }, [slug, tokenSets]);

  const themeFileContent = useMemo<Theme[]>(() => {
    const file = tokenSet?.attributes.files.find((file) => file.filePath.includes('themes.json'));
    if (file) {
      return JSON.parse(file.content);
    }
    return uploadedThemes;
  }, [tokenSet, uploadedThemes]);

  const setTheme = (theme: string) => {
    setSelectedTheme(theme);
    iframeRef.current?.contentWindow?.postMessage({ type: 'set-theme', themeName: theme }, '*');
    Mixpanel.track('Switch theme', { theme });
  };

  useEffect(() => {
    setSelectedTheme('');
  }, [slug]);

  const selectItems = themeFileContent.map((theme) => ({ id: theme.id, value: theme.name, label: theme.name }));

  return !!themeFileContent.length ? (
    <Select
      items={selectItems}
      defaultValue={themeFileContent[0] ? themeFileContent[0].name : ''}
      onChange={setTheme}
      label="Theme"
      value={selectedTheme}
    />
  ) : null;
}
