import { useCallback, useMemo } from 'react';
import { ConfirmState, defaultConfirmState, useConfirm } from '../context/ConfirmContext';

type ResolveCallbackPayload<C = any> =
  | false
  | {
      result: true;
      data: C;
    };

let resolveCallback: (payload: ResolveCallbackPayload<any>) => void = () => {};

function useConfirmModal<C = any>() {
  const { confirmState, setConfirmState } = useConfirm();

  const confirm = useCallback(
    (opts: ConfirmState) => {
      setConfirmState(opts);

      return new Promise<ResolveCallbackPayload<C>>((res) => {
        resolveCallback = res;
      });
    },
    [setConfirmState]
  );

  const closeConfirm = useCallback(() => {
    setConfirmState(defaultConfirmState);
  }, [setConfirmState]);

  const onCancel = useCallback(() => {
    resolveCallback(false);
    closeConfirm();
  }, [closeConfirm]);

  const onConfirm = useCallback(
    (data: C) => {
      resolveCallback({ result: true, data });
      closeConfirm();
    },
    [closeConfirm]
  );

  return useMemo(
    () => ({
      confirm,
      onConfirm,
      onCancel,
      confirmState,
    }),
    [confirm, onConfirm, onCancel, confirmState]
  );
}

export default useConfirmModal;
