import React, {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import { Theme } from '../components/ThemeDropdown';

export interface PlaygroundContextType {
  iframeRef: RefObject<HTMLIFrameElement>;
  iframeLoaded: boolean;
  setIframeLoaded: Dispatch<SetStateAction<boolean>>;
  setUploadedThemes: Dispatch<SetStateAction<Theme[]>>;
  uploadedThemes: Theme[];
  setPlaygroundLoading: Dispatch<SetStateAction<boolean>>;
  playgroundLoading: boolean;
  dragOverlayVisible: boolean;
  setDragOverlayVisible: Dispatch<SetStateAction<boolean>>;
}

const PlaygroundContext = createContext<PlaygroundContextType | undefined>(undefined);

function PlaygroundContextProvider({ children }: { children: ReactNode }) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [uploadedThemes, setUploadedThemes] = useState<Theme[]>([]);
  const [playgroundLoading, setPlaygroundLoading] = useState(false);
  const [dragOverlayVisible, setDragOverlayVisible] = useState(false);

  return (
    <PlaygroundContext.Provider
      value={{
        iframeRef,
        iframeLoaded,
        setIframeLoaded,
        uploadedThemes,
        setUploadedThemes,
        playgroundLoading,
        setPlaygroundLoading,
        dragOverlayVisible,
        setDragOverlayVisible,
      }}
    >
      {children}
    </PlaygroundContext.Provider>
  );
}

function usePlaygroundContext() {
  const context = useContext(PlaygroundContext);

  if (context === undefined) {
    throw new Error('usePlaygroundContext must be used within a PlaygroundContextProvider');
  }
  return context;
}

export { PlaygroundContextProvider, usePlaygroundContext };
