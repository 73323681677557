import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTokenSets } from '../hooks/useTokenSets';
import { Box, Button, Flex, IconButton, InlineBox } from '../shared/components';
import ChevronLeft from '../shared/svgs/ChevronLeft';
import ChevronRight from '../shared/svgs/ChevronRight';

export default function TokenSetInfo() {
  const { tokenSets } = useTokenSets();
  const { slug, mode } = useParams();
  const navigate = useNavigate();

  const selectedIndex = useMemo(() => {
    return tokenSets.findIndex((item) => item.attributes.setId === slug);
  }, [tokenSets, slug]);

  const tokenSet = useMemo(() => {
    return tokenSets.find((item) => item.attributes.setId === slug);
  }, [slug, tokenSets]);

  let author;
  let twitterHandle;
  let setName;

  if (tokenSet?.attributes) {
    ({ author, twitterHandle, setName } = tokenSet?.attributes);
  }

  const onPrevPage = () => {
    const prevSet = tokenSets[selectedIndex - 1];
    navigate(`/explore/${prevSet.attributes.setId}`);
  };

  const onNextPage = () => {
    const nextSet = tokenSets[selectedIndex + 1];
    navigate(`/explore/${nextSet.attributes.setId}`);
  };

  const onDiscard = () => {
    navigate('/explore');
  };

  if (mode === 'new') {
    return (
      <Flex css={{ gap: '$5' }}>
        <InlineBox css={{ color: '$fgDefault', fontSize: '$xlarge', fontWeight: '$bold' }}>My custom set</InlineBox>
        <Button variant="secondary" onClick={onDiscard}>
          Discard
        </Button>
      </Flex>
    );
  }

  return tokenSet ? (
    <Flex css={{ gap: '$7' }}>
      <IconButton onClick={onPrevPage} icon={<ChevronLeft />} variant="secondary" disabled={selectedIndex === 0} />
      <Flex css={{ color: '$fgDefault', flexDirection: 'column', alignItems: 'start', fontSize: '$large' }}>
        {setName && (
          <Box>
            <InlineBox css={{ color: '$fgDefault', fontSize: '$xlarge', fontWeight: '$bold' }}>{setName}</InlineBox>
          </Box>
        )}
        {author && (
          <Flex css={{ gap: '$2', fontSize: '$small' }}>
            <InlineBox css={{ color: '$fgMuted' }}>{`by ${author}`}</InlineBox>
            <InlineBox>/</InlineBox>
            <InlineBox css={{ color: '$fgMuted' }}>
              <a
                style={{ color: 'inherit' }}
                href={`https://twitter.com/${twitterHandle}`}
                target="_blank"
                rel="noreferrer"
              >
                {twitterHandle}
              </a>
            </InlineBox>
          </Flex>
        )}
      </Flex>
      <IconButton
        onClick={onNextPage}
        icon={<ChevronRight />}
        variant="secondary"
        disabled={selectedIndex === tokenSets.length - 1}
      />
    </Flex>
  ) : null;
}
