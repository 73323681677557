import React from 'react';

function ChevronLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" viewBox="0 0 15 16">
      <path
        fill="inherit"
        fillRule="evenodd"
        d="M8.842 3.635a.5.5 0 01.023.707L5.435 8l3.43 3.658a.5.5 0 01-.73.684l-3.75-4a.5.5 0 010-.684l3.75-4a.5.5 0 01.707-.023z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ChevronLeft;
