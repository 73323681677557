import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DropZone, Header, Playground } from '../components';
import { UploadedFile } from '../components/Dropzone';
import { usePlaygroundContext } from '../context/PlaygroundContext';
import useDefaultSetId from '../hooks/useDefaultSetId';
import { useTemplateFiles } from '../hooks/useTemplateFiles';
import { useTokenSets } from '../hooks/useTokenSets';
import { Mixpanel } from '../Mixpanel';
import { Box } from '../shared/components';

export default function Home() {
  const templateFiles = useTemplateFiles();
  const { iframeRef, iframeLoaded, setPlaygroundLoading, setUploadedThemes } = usePlaygroundContext();
  const { tokenSets, isLoading: loadingTokenSets } = useTokenSets();
  const { mode, slug } = useParams();
  const navigate = useNavigate();
  const setId = useDefaultSetId();

  const tokenSet = useMemo(() => {
    return tokenSets.find((item) => item.attributes.setId === slug);
  }, [slug, tokenSets]);

  // load the tokens into the playground iframe
  useEffect(() => {
    if (mode === 'explore' && iframeLoaded && tokenSet && templateFiles) {
      const files = tokenSet?.attributes.files || [];
      const playgroundFiles = [...files, ...templateFiles];
      iframeRef.current?.contentWindow?.postMessage({ type: 'load-tokens', files: playgroundFiles }, '*');

      const { setId, setName, default: defaultSet, author, userEmail, twitterHandle } = tokenSet.attributes;
      Mixpanel.track(`View set ${setId} - ${setName}`, {
        userEmail,
        setName,
        twitterHandle,
        author,
        default: defaultSet,
      });
    }
  }, [slug, tokenSet, iframeRef, iframeLoaded, templateFiles, mode, navigate, setPlaygroundLoading, loadingTokenSets]);

  // if theres no hash specified, load the default set
  useEffect(() => {
    if (!slug && !loadingTokenSets && mode === 'explore') {
      navigate(`${setId}`);
    }
  }, [slug, mode, tokenSets, navigate, setId, loadingTokenSets]);

  // set the loading state for the playground, the playground sends back an event when its loaded, and this is set to false
  useEffect(() => {
    if (mode === 'explore') {
      setPlaygroundLoading(true);
    }
  }, [mode, setPlaygroundLoading, slug]);

  // used by the upload button & dropzone
  const onFileUpload = (files: UploadedFile[]) => {
    const themeFile = files.find((file) => file.filePath.includes('themes.json'));
    if (themeFile) {
      setUploadedThemes(JSON.parse(themeFile.content));
    }
    if (iframeRef) {
      navigate('/new');
      iframeRef.current?.contentWindow?.postMessage({ type: 'load-tokens', files }, '*');
    }
  };

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DropZone onUpload={onFileUpload} />
      <Box css={{ flexGrow: 0 }}>
        <Header onFileUpload={onFileUpload} />
      </Box>
      <Box css={{ flexGrow: 1, overflow: 'hidden', position: 'relative' }}>
        <Box css={{ flexGrow: 1, height: '100%' }}>{<Playground />}</Box>
      </Box>
    </Box>
  );
}
