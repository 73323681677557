import useSWR from 'swr';
import { UploadedFile } from '../components/Dropzone';

interface ApiResponse {
  data: Data[];
  metadata: {
    pagination: {
      page: number;
      pageCount: number;
      pageSize: number;
      total: number;
    };
  };
}

export interface TokenSetData {
  setId: string;
  userEmail: string;
  approved: boolean;
  default?: boolean;
  twitterHandle: string;
  author: string;
  setName: string;
  files: UploadedFile[];
}

interface Data {
  id: number;
  attributes: TokenSetData;
}

export function useTokenSets() {
  const { data, error, mutate } = useSWR<ApiResponse>(
    `${process.env.REACT_APP_BACKEND_URL}api/token-sets?pagination[page]=1&pagination[pageSize]=100`
  );

  return {
    tokenSets: data?.data || [],
    isLoading: !error && !data?.data,
    error,
    mutate,
  };
}
