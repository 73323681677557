import React from 'react';
import { useParams } from 'react-router-dom';
import { UploadButton, ThemeDropdown, CollapseEditor } from '.';
import { Box, Button, Flex } from '../shared/components';
import { UploadedFile } from './Dropzone';
import TokenSetInfo from './TokenSetInfo';
import PublishButton from './PublishButton';
import Stack from '../shared/svgs/Stack';
import { Mixpanel } from '../Mixpanel';

interface HeaderProps {
  onFileUpload: (files: UploadedFile[]) => void;
}

export default function Header({ onFileUpload }: HeaderProps) {
  const { mode } = useParams();

  const onCreateClick = () => {
    Mixpanel.track('Create your own click');
    window.open('https://www.figma.com/community/file/1172564146985130196', '_blank');
  };

  const createYourOwnButton = (
    <Button variant="primary" onClick={onCreateClick} icon={<Stack />}>
      Create your own
    </Button>
  );

  return (
    <Flex
      css={{
        justifyContent: 'space-between',
        padding: '$5',
        background: '$canvasDefault',
        borderBottom: '1px solid $borderMuted',
        height: '68px',
      }}
    >
      <Flex css={{ gap: '$3', flexGrow: 0 }}>
        <CollapseEditor />
        <ThemeDropdown />
      </Flex>
      <TokenSetInfo />
      <Flex css={{ gap: '$3' }}>
        <UploadButton onUpload={onFileUpload} />
        <Box>{mode === 'new' ? <PublishButton /> : createYourOwnButton}</Box>
      </Flex>
    </Flex>
  );
}
