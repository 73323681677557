import mixpanel, { Dict } from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ?? '');

const env_check = process.env.NODE_ENV === 'production';

const actions = {
  identify: (id: string) => {
    if (env_check) {
      try {
        mixpanel.identify(id);
      } catch (error) {
        console.log(error);
      }
    }
  },
  alias: (id: string) => {
    if (env_check) {
      try {
        mixpanel.alias(id);
      } catch (error) {
        console.log(error);
      }
    }
  },
  track: (name: string, props?: Dict) => {
    if (env_check) {
      try {
        mixpanel.track(name, props);
      } catch (error) {
        console.log(error);
      }
    }
  },
  people: {
    set: (props: Dict) => {
      if (env_check) {
        try {
          mixpanel.people.set(props);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};

export const Mixpanel = actions;

const onMessage = (ev: MessageEvent) => {
  switch (ev.data.type) {
    case 'download-files':
      Mixpanel.track('Download files');
      break;
    case 'new-file':
      Mixpanel.track('Create new file', { fileName: ev.data.fileName });
      break;
    case 'delete-file':
      Mixpanel.track('Delete file', { fileName: ev.data.fileName });
      break;
    case 'run-sd':
      Mixpanel.track('Run SD');
      break;
    case 'view-css':
      Mixpanel.track('View css output');
      break;
    case 'view-js':
      Mixpanel.track('View js output');
      break;

    default:
      break;
  }
};

window.addEventListener('message', onMessage);
