import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import { Home } from './pages';
import { Box } from './shared/components';

function NotFound() {
  return <h1>Not found</h1>;
}

export default function App() {
  return (
    <Box css={{ height: '100%' }}>
      <Routes>
        <Route path="/:mode" element={<Home />} />
        <Route path="/:mode/:slug" element={<Home />} />
        <Route path="/" element={<Navigate to="/explore" replace />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>
    </Box>
  );
}
