import React from 'react';

function Code() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" viewBox="0 0 15 16">
      <path
        fill="inherit"
        fillRule="evenodd"
        d="M9.964 3.186a.5.5 0 10-.928-.372l-4 10a.5.5 0 10.928.372l4-10zm-6.11 2.46a.5.5 0 010 .708L2.207 8l1.647 1.646a.5.5 0 01-.708.708l-2-2a.5.5 0 010-.708l2-2a.5.5 0 01.708 0zm7.292 0a.5.5 0 01.708 0l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L12.793 8l-1.647-1.646a.5.5 0 010-.708z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Code;
