import { styled } from '../../stitches.config';

const Button = styled('button', {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$medium',
  padding: '0 $5',
  fontSize: '$large',
  fontFamily: '$int',
  lineHeight: 1,
  fontWeight: 600,
  height: 32,
  cursor: 'pointer',
  '&:disabled': {
    opacity: 0.5,
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: '$bgButtonPrimary',
        color: '$fgDefault',
      },
      secondary: {
        backgroundColor: '$bgButtonSecondary',
        color: '$fgDefault',
        border: '1px solid $borderMuted',
        svg: {
          fill: '$fgDefault',
        },
      },
    },
  },

  defaultVariants: {
    variant: 'primary',
  },
});

interface IconButtonProps {
  icon: React.ReactNode;
  variant: 'primary' | 'secondary';
  disabled?: boolean;
  onClick: () => void;
}

const IconButton = ({ icon, variant, disabled = false, onClick }: IconButtonProps) => {
  return (
    <Button css={{ padding: '0 $3' }} onClick={onClick} disabled={disabled} variant={variant}>
      {icon}
    </Button>
  );
};

export default IconButton;
