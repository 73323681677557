import { styled, keyframes } from '../../stitches.config';

const spinner = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
});

const Spinner = styled('div', {
  display: 'inline-block',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'rgba(255,255,255, 0.2)',
  borderRadius: '50px',
  animation: `${spinner}  800ms linear infinite`,
  variants: {
    color: {
      white: {
        borderTopColor: '$fgDefault',
      },
    },
    size: {
      '1': {
        width: '20px',
        height: '20px',
      },
      '2': {
        width: '40px',
        height: '40px',
      },
    },
    thickness: {
      '1': {
        borderWidth: 3,
      },
      '2': {
        borderWidth: 4,
      },
    },
  },
});

export default Spinner;
