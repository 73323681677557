export const fetcher = async (url: string, method = 'GET', data: null | Record<string, any>) => {
  return fetch(url, {
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    ...(data ? { body: JSON.stringify(data) } : {}),
  }).then(async (res) => {
    if (res.status === 200) {
      return res.json();
    }

    throw await res.json();
  });
};
