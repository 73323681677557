import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Box, ConfirmAlert, NotificationToast } from './shared/components';
import { SWRConfig } from 'swr';
import { fetcher } from './api/fetcher';
import * as Toast from '@radix-ui/react-toast';
import { ConfirmContextProvider } from './context/ConfirmContext';
import { NotificationContextProvider } from './context/NotificationContext';
import { BrowserRouter } from 'react-router-dom';
import { PlaygroundContextProvider } from './context/PlaygroundContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Box css={{ height: '100vh' }}>
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <PlaygroundContextProvider>
        <NotificationContextProvider>
          <ConfirmContextProvider>
            <Toast.Provider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
              <ConfirmAlert />
              <NotificationToast />
            </Toast.Provider>
          </ConfirmContextProvider>
        </NotificationContextProvider>
      </PlaygroundContextProvider>
    </SWRConfig>
  </Box>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
