import { useEffect, useState } from 'react';
import { UploadedFile } from '../components/Dropzone';
import { usePlaygroundContext } from '../context/PlaygroundContext';

const isProduction = process.env.NODE_ENV === 'production';

const templateFiles = [
  {
    path: 'playground-templates/template.html',
    playgroundPath: 'index.html',
  },
  {
    path: 'playground-templates/template.css',
    playgroundPath: 'styles.css',
  },
  {
    path: 'playground-templates/package.json',
    playgroundPath: 'package.json',
  },
  {
    path: 'playground-templates/transformTokens.js',
    playgroundPath: 'transformTokens.js',
  },
  {
    path: 'playground-templates/README.md',
    playgroundPath: 'README.MD',
  },
];

export function useTemplateFiles() {
  const { setPlaygroundLoading } = usePlaygroundContext();
  const [files, setFiles] = useState<UploadedFile[]>([]);

  useEffect(() => {
    async function fetchFiles() {
      const filePromises = templateFiles.map(async ({ path, playgroundPath }) => {
        const res = await fetch(`${isProduction ? 'https' : 'http'}://${window.location.host}/${path}`);
        const fileText = await res.text();
        return {
          filePath: playgroundPath,
          content: fileText,
        };
      });

      const files = await Promise.all(filePromises);
      setFiles(files);
    }

    fetchFiles();
  }, [setPlaygroundLoading]);

  return files;
}
