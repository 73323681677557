import React, { ChangeEvent, useCallback } from 'react';
import { FileWithPath } from 'react-dropzone';
import { usePlaygroundContext } from '../context/PlaygroundContext';
import { useTemplateFiles } from '../hooks/useTemplateFiles';
import { Mixpanel } from '../Mixpanel';
import { Button } from '../shared/components';
import Upload from '../shared/svgs/Upload';
import { readFile } from '../utils/readFile';
import { UploadedFile } from './Dropzone';

interface UploadButtonProps {
  onUpload: (files: UploadedFile[]) => void;
}

export default function UploadButton({ onUpload }: UploadButtonProps) {
  const { setPlaygroundLoading } = usePlaygroundContext();
  const templateFiles = useTemplateFiles();

  const readAndUpload = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      try {
        const filesPromises = acceptedFiles.map((file) => readFile(file));
        const files = await Promise.all(filesPromises);
        onUpload([...files, ...templateFiles]);
        Mixpanel.track('Upload tokens - button');
      } catch (error) {
        console.log(error);
      }
    },
    [onUpload, templateFiles]
  );

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setPlaygroundLoading(true);
      const acceptedFiles = Array.from(event.target.files).filter((file) => file.webkitRelativePath.includes('.json'));

      readAndUpload(acceptedFiles);
    }
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <label htmlFor="folder">
        <Button css={{ pointerEvents: 'none' }} variant="secondary" onClick={(ev) => {}} icon={<Upload />}>
          Upload
        </Button>
      </label>
      <input
        onChange={onChange}
        hidden
        type="file"
        id="folder"
        webkitdirectory=""
        directory=""
        multiple
        accept="application/json"
      />
    </div>
  );
}

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}
