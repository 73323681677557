import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface ConfirmState {
  title: string;
  description: string;
  cancelButtonText: string;
  confirmButtonText: string;
  visible: boolean;
}

export interface ConfirmContextType {
  confirmState: ConfirmState | undefined;
  setConfirmState: React.Dispatch<React.SetStateAction<ConfirmState>>;
}

export const defaultConfirmState = {
  title: '',
  description: '',
  cancelButtonText: '',
  confirmButtonText: '',
  visible: false,
};

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

function ConfirmContextProvider({ children }: { children: ReactNode }) {
  const [confirmState, setConfirmState] = useState<ConfirmState>(defaultConfirmState);

  return <ConfirmContext.Provider value={{ confirmState, setConfirmState }}>{children}</ConfirmContext.Provider>;
}

function useConfirm() {
  const context = useContext(ConfirmContext);
  if (context === undefined) {
    throw new Error('useConfirm must be used within a ConfirmContextProvider');
  }
  return context;
}

export { ConfirmContextProvider, useConfirm };
