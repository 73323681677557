import { useTokenSets } from './useTokenSets';

export default function useDefaultSetId() {
  const { tokenSets } = useTokenSets();
  let setId: string | number = 0;

  if (tokenSets.length) {
    const defaultSet = tokenSets.find((item) => item.attributes.default === true);
    if (defaultSet) {
      setId = defaultSet.attributes.setId;
    } else {
      setId = tokenSets[0].attributes.setId;
    }
  }

  return setId;
}
