import React from 'react';
import useConfirmModal from '../../hooks/useConfirmModal';
import { Button, Flex } from '.';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { styled } from '../../stitches.config';
import { keyframes } from '@stitches/react';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const AlertOverlay = styled(AlertDialogPrimitive.Overlay, {
  opacity: 0.5,
  backgroundColor: '$canvasSubtle',
  position: 'fixed',
  inset: 0,
});

const AlertContainer = styled(AlertDialogPrimitive.Content, {
  backgroundColor: '$canvasSubtle',
  borderRadius: 6,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '360px',
  maxHeight: '85vh',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': { outline: 'none' },
});

const StyledTitle = styled(AlertDialogPrimitive.Title, {
  margin: 0,
  padding: '$6 $8',
  color: '$fgDefault',
  background: '$canvasInset',
  fontSize: '$xxxlarge',
  fontWeight: '$semiBold',
});

const StyledDescription = styled(AlertDialogPrimitive.Description, {
  padding: '$5 $8 0 $8',
  color: '$fgDefault',
  fontSize: '$xlarge',
});

function AlertDialogContent({ children, ...props }: { children: React.ReactNode }) {
  return (
    <AlertDialogPrimitive.Portal>
      <AlertOverlay />
      <AlertContainer {...props}>{children}</AlertContainer>
    </AlertDialogPrimitive.Portal>
  );
}

export default function ConfirmAlert() {
  const { confirmState, onCancel, onConfirm } = useConfirmModal();

  return (
    <AlertDialogPrimitive.Root open={confirmState?.visible}>
      <AlertDialogContent>
        <StyledTitle>{confirmState?.title}</StyledTitle>
        <StyledDescription>{confirmState?.description}</StyledDescription>
        <Flex css={{ justifyContent: 'space-between', padding: '$4 $8 $7 $8' }}>
          <AlertDialogPrimitive.Cancel asChild>
            <Button variant="secondary" css={{ marginRight: 25 }} onClick={onCancel}>
              {confirmState?.cancelButtonText}
            </Button>
          </AlertDialogPrimitive.Cancel>
          <AlertDialogPrimitive.Action asChild>
            <Button variant="primary" onClick={onConfirm}>
              {confirmState?.confirmButtonText}
            </Button>
          </AlertDialogPrimitive.Action>
        </Flex>
      </AlertDialogContent>
    </AlertDialogPrimitive.Root>
  );
}
