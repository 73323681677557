import React, { useEffect } from 'react';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { usePlaygroundContext } from '../context/PlaygroundContext';
import { Flex, Spinner } from '../shared/components';

export default function Playground() {
  const { iframeRef, setIframeLoaded, playgroundLoading, setPlaygroundLoading, dragOverlayVisible } =
    usePlaygroundContext();
  const { addNotification } = useNotifications();

  async function onMessage(ev: MessageEvent) {
    if (ev.data.type === 'playground-loaded') {
      setPlaygroundLoading(false);
    } else if (ev.data.type === 'error') {
      addNotification(NotificationTypes.ERORR, ev.data.error);
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  });

  const loadingOverlay = playgroundLoading && (
    <Flex
      css={{
        width: '100%',
        height: '100%',
        background: '$canvasDefault',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
      }}
    >
      <Spinner size="2" thickness="2" color="white" />
    </Flex>
  );

  return (
    <>
      <iframe
        style={{ border: 'none', pointerEvents: dragOverlayVisible ? 'none' : 'auto' }}
        onLoad={() => setIframeLoaded(true)}
        ref={iframeRef}
        id="playground"
        width="100%"
        height="100%"
        title="Figma tokens playground"
        src={process.env.REACT_APP_PLAYGROUND_URL}
      ></iframe>
      {loadingOverlay}
    </>
  );
}
