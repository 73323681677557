import React, { useCallback, useEffect } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { usePlaygroundContext } from '../context/PlaygroundContext';
import { useTemplateFiles } from '../hooks/useTemplateFiles';
import { Mixpanel } from '../Mixpanel';
import { Box, Flex } from '../shared/components';
import Upload from '../shared/svgs/Upload';
import { readFile } from '../utils/readFile';

export type UploadedFile = {
  filePath: string;
  content: string;
};

interface DropzoneProps {
  onUpload: (files: UploadedFile[]) => void;
}

function Dropzone({ onUpload }: DropzoneProps) {
  const { setPlaygroundLoading, setDragOverlayVisible, dragOverlayVisible } = usePlaygroundContext();
  const templateFiles = useTemplateFiles();

  async function onMessage(ev: MessageEvent) {
    if (ev.data.type === 'drag-enter') {
      setDragOverlayVisible(true);
    } else if (ev.data.type === 'drag-leave') {
      setDragOverlayVisible(false);
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  });

  const onDrop = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      setDragOverlayVisible(false);
      setPlaygroundLoading(true);
      const filesPromises = acceptedFiles.map((file) => readFile(file));
      const files = await Promise.all(filesPromises);
      onUpload([...files, ...templateFiles]);
      Mixpanel.track('Upload tokens - drag and drop');
    },
    [onUpload, templateFiles, setPlaygroundLoading, setDragOverlayVisible]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragLeave: () => {
      setDragOverlayVisible(false);
    },
    accept: { 'application/json': ['.json'] },
  });

  return (
    <Flex
      css={{
        top: 0,
        left: 0,
        position: 'fixed',
        opacity: dragOverlayVisible ? 100 : 0,
        pointerEvents: dragOverlayVisible ? 'auto' : 'none',
        zIndex: 2,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.9)',
        padding: '$6',
        justifyContent: 'center',

        alignItems: 'center',
        color: '$fgDefault',
      }}
      {...getRootProps()}
    >
      <Flex
        css={{
          background: '$canvasDefault',
          border: '1px solid $borderMuted',
          borderRadius: '$medium',
          padding: '$5',
          width: '445px',
          height: '445px',
          svg: {
            fill: '$fgDefault',
          },
        }}
      >
        <Flex css={{ width: '100%', height: '100%', border: '2px dashed #5F6B7A', borderRadius: '$medium' }}>
          <Flex css={{ flexDirection: 'column', gap: '$5', textAlign: 'center', padding: '0 $8' }}>
            <Upload />
            <Box css={{ fontSize: '$xlarge', color: '$fgDefault', fontWeight: '$semiBold', fontFamily: '$int' }}>
              Drop a folder
            </Box>
            <Box css={{ fontSize: '$small', color: '$fgSubtle' }}>
              Upload a folder containing design tokens in individual .json files (ideally exported using Figma Tokens)
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <input {...getInputProps()} />
    </Flex>
  );
}

export default Dropzone;
