import React, { useState } from 'react';
import { Mixpanel } from '../Mixpanel';
import { Button } from '../shared/components';
import PublishModal from './PublishModal';

export default function PublishButton() {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          Mixpanel.track('Open publish modal');
          setModalVisible(true);
        }}
        variant="primary"
      >
        Publish
      </Button>
      <PublishModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
}
