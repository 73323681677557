import React from 'react';

function Upload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15">
      <path
        fill="inherit"
        fillRule="evenodd"
        d="M7.818 1.182a.45.45 0 00-.636 0l-3 3a.45.45 0 10.636.636L7.05 2.586V9.5a.45.45 0 10.9 0V2.586l2.232 2.232a.45.45 0 10.636-.636l-3-3zM2.5 10a.5.5 0 01.5.5V12c0 .554.446 1 .996 1h7.005A.999.999 0 0012 12v-1.5a.5.5 0 111 0V12a2 2 0 01-1.999 2H3.996A1.997 1.997 0 012 12v-1.5a.5.5 0 01.5-.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Upload;
