import React, { useState } from 'react';
import { usePlaygroundContext } from '../context/PlaygroundContext';
import { Mixpanel } from '../Mixpanel';
import { IconButton } from '../shared/components';
import Code from '../shared/svgs/Code';

export default function CollapseEditor() {
  const { iframeRef } = usePlaygroundContext();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed((isCollapsed) => !isCollapsed);

    if (collapsed) {
      iframeRef.current?.contentWindow?.postMessage({ type: 'show-editor' }, '*');
      Mixpanel.track('Show editor');
    } else {
      iframeRef.current?.contentWindow?.postMessage({ type: 'hide-editor' }, '*');
      Mixpanel.track('Hide editor');
    }
  };

  return <IconButton onClick={toggleCollapsed} variant="secondary" icon={<Code />} />;
}
