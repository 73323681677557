import { ReactNode } from 'react';
import { styled } from '../../stitches.config';

const BaseButton = styled('button', {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$4',
  borderRadius: '$medium',
  padding: '0 $5',
  fontSize: '$xsmall',
  fontFamily: '$int',
  lineHeight: 1,
  fontWeight: '$semiBold',
  height: 32,
  cursor: 'pointer',
  svg: {
    fill: '$fgDefault',
  },
  '&:disabled': {
    opacity: 0.5,
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: '$bgButtonPrimary',
        color: '$fgDefault',
      },
      secondary: {
        backgroundColor: '$bgButtonSecondary',
        color: '$fgDefault',
        border: '1px solid $borderMuted',
      },
    },
  },

  defaultVariants: {
    variant: 'primary',
  },
});

interface ButtonProps {
  children: ReactNode;
  icon?: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  variant: 'primary' | 'secondary';
}

const Button = styled(({ children, variant, icon, onClick, ...rest }: ButtonProps) => {
  return (
    <BaseButton variant={variant} onClick={onClick} {...rest}>
      {icon}
      {children}
    </BaseButton>
  );
});

export default Button;
